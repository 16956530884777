import styled from 'styled-components';

import Text from '@/atoms/Text';

import theme from '@/styles/theme';

export default styled(Text).attrs(props => ({
  t: 1,
  pt: [1.25, 1.25, 1.25, 1.5],
  pb: [0.75, 0.75, 0.75, 1],
  $color: props.$color || 'black',
}))`
  border-top: 1px solid ${props => theme.colors[props.$color]};
`;
