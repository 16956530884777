import React from 'react';

import Text from '@/atoms/Text';

import theme from '@/styles/theme';

export default ({ layout, ...props }) => (
  <Text
    t={-2}
    pt={0.5}
    pr={layout === 'bleed' ? theme.grid.outerMargin : 0}
    $textAlign="right"
    $color="gray"
    {...props}
  />
);
