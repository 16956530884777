import React from 'react';
import { graphql } from 'gatsby';

import Video from './Video';
import Image from '@/components/Image';
import Grid from '@/atoms/Grid';
import Caption from '@/atoms/Caption';

import { SPACING } from '@/utils/enums';

export default ({ spacingBottom, caption, assets, textColor, ...props }) => {
  if (!assets || assets.length === 0) {
    return null;
  }

  const columnWidth = 1 / assets.length;
  const marginBottom = SPACING[spacingBottom || 'normal'];

  return (
    <Grid.Container mb={marginBottom} {...props}>
      <Grid>
        {assets.map(({ key, id, type, ...asset }) => (
          <Grid.Item key={key || id} w={columnWidth}>
            {type === 'vimeo' ? (
              <Video columnWidth={columnWidth} {...asset} />
            ) : (
              <Image columnWidth={columnWidth} {...asset} />
            )}
          </Grid.Item>
        ))}
      </Grid>

      {caption && <Caption>{caption}</Caption>}
    </Grid.Container>
  );
};

export const query = graphql`
  fragment moduleAssetGridData on SanityModuleAssetGrid {
    spacingBottom
    caption
    assets {
      ... on SanityImageWithAlt {
        key: _key
        type: _type
        ...imageWithAltData
      }
      ... on SanityVimeo {
        id
        type: _type
        ...vimeoData
      }
    }
  }
`;
