import styled from 'styled-components';

import Grid from '@/atoms/Grid';

import theme from '@/styles/theme';
import { media, scale } from '@/styles/utils';

export default styled(Grid.Item).attrs(props => ({
  $color: props.$color || 'black',
}))`
  position: relative;
  height: 1px;

  ::before {
    position: absolute;
    top: 0;
    left: ${scale(theme.grid.gutter[0] / 2)};
    right: ${scale(theme.grid.gutter[0] / 2)};
    content: '';
    display: block;
    height: 1px;
    background: ${props => theme.colors[props.$color]};

    ${theme.grid.gutter[1] &&
      media.sm`
      left: ${scale(theme.grid.gutter[1] / 2)};
      right: ${scale(theme.grid.gutter[1] / 2)};
    `}

    ${theme.grid.gutter[2] &&
      media.md`
      left: ${scale(theme.grid.gutter[2] / 2)};
      right: ${scale(theme.grid.gutter[2] / 2)};
    `}

    ${theme.grid.gutter[3] &&
      media.lg`
      left: ${scale(theme.grid.gutter[3] / 2)};
      right: ${scale(theme.grid.gutter[3] / 2)};
    `}

    ${theme.grid.gutter[4] &&
      media.xl`
      left: ${scale(theme.grid.gutter[4] / 2)};
      right: ${scale(theme.grid.gutter[4] / 2)};
    `}
  }
`;
