import React from 'react';

import LogoAnimation from '@/components/LogoAnimation';
import Grid from '@/atoms/Grid';

export default ({ textLeft, textRight, textColor, ...props }) => {
  return (
    <Grid.Container {...props}>
      <LogoAnimation $color={textColor} pb={2} delay={0} />
    </Grid.Container>
  );
};
