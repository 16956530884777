import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import Clock from '@/components/Clock';
import Grid from '@/atoms/Grid';
import Text from '@/atoms/Text';
import RichText from '@/atoms/RichText';

import { mapToSingleObject } from '@/utils/helpers';

import theme from '@/styles/theme';

const ClockWithOverline = styled(Clock)`
  border-top: 1px solid ${props => theme.colors[props.textColor]};
`;

export default ({ textNYC, textCPH, textPAR, textColor, ...props }) => {
  const { globalData } = useStaticQuery(graphql`
    query {
      globalData: allSanitySiteSettingsInformation {
        edges {
          node {
            newBusinessContact {
              label
              url
            }
          }
        }
      }
    }
  `);

  const info = mapToSingleObject(globalData);

  return (
    <Grid.Container mb={theme.spacing.large} {...props}>
      <Text t={1} mb={[3, 3, 1]}>
        <a
          href={info.newBusinessContact.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {info.newBusinessContact.label}
        </a>
      </Text>

      <Grid>
        {/* NYC */}
        <Grid.Item w={[1, 1, 4 / 12]} mb={[5, 5, 0]}>
          <ClockWithOverline
            timezone="NYC"
            pt={[1.5, 1.5, 2, 3]}
            pb={[1.5, 1.5, 2, 3]}
          />

          <Text
            t={1}
            as="h3"
            pb={[1, 1, 1, 2]}
            $color={theme.colorSchemes[textColor].title}
          >
            {textNYC.title}
          </Text>

          <RichText
            blocksRaw={textNYC.text.textRaw}
            $color={theme.colorSchemes[textColor].title}
            $maxWidth={18}
            pb={[0.5, 0.5, 2.5]}
          />
        </Grid.Item>

        {/* CPH */}
        <Grid.Item w={[1, 1, 4 / 12]} mb={[5, 5, 0]}>
          <ClockWithOverline
            timezone="CPH"
            pt={[1.5, 1.5, 2, 3]}
            pb={[1.5, 1.5, 2, 3]}
          />

          <Text
            t={1}
            as="h3"
            pb={[1, 1, 1, 2]}
            $color={theme.colorSchemes[textColor].title}
          >
            {textCPH.title}
          </Text>

          <RichText
            blocksRaw={textCPH.text.textRaw}
            $color={theme.colorSchemes[textColor].title}
            $maxWidth={18}
            pb={[0.5, 0.5, 2.5]}
          />
        </Grid.Item>

        {/* PAR */}
        <Grid.Item w={[1, 1, 4 / 12]} mb={[5, 5, 0]}>
          <ClockWithOverline
            timezone="PAR"
            pt={[1.5, 1.5, 2, 3]}
            pb={[1.5, 1.5, 2, 3]}
          />

          <Text
            t={1}
            as="h3"
            pb={[1, 1, 1, 2]}
            $color={theme.colorSchemes[textColor].title}
          >
            {textPAR.title}
          </Text>

          <RichText
            blocksRaw={textPAR.text.textRaw}
            $color={theme.colorSchemes[textColor].title}
            $maxWidth={18}
            pb={[0.5, 0.5, 2.5]}
          />
        </Grid.Item>
      </Grid>
    </Grid.Container>
  );
};

export const query = graphql`
  fragment moduleContactInfoData on SanityModuleContactInfo {
    textNYC {
      title
      text {
        textRaw: _rawText
      }
    }
    textCPH {
      title
      text {
        textRaw: _rawText
      }
    }
    textPAR {
      title
      text {
        textRaw: _rawText
      }
    }
  }
`;
