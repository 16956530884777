import React from 'react';
import styled, { keyframes } from 'styled-components';
import { DateTime } from 'luxon';

import Box from '@/atoms/Box';

import { media, scale } from '@/styles/utils';
import theme from '@/styles/theme';

const handRotation = startPos => keyframes`
  from {
    transform: rotate(${startPos}deg);
  }
  to {
    transform: rotate(${startPos + 360}deg);
  }
`;

const Face = styled.div`
  position: relative;
  width: ${scale(15)};
  height: ${scale(15)};
  border: 1px solid ${theme.colors.black};
  border-radius: 50%;

  ${media.md`
    width: ${scale(11)};
    height: ${scale(11)};
  `}

  ${media.lg`
    width: ${scale(16)};
    height: ${scale(16)};
  `};

  ${media.xl`
    width: ${scale(20)};
    height: ${scale(20)};
  `};

  ::before {
    position: absolute;
    content: '';
    display: block;
    top: 6%;
    bottom: 6%;
    left: 6%;
    right: 6%;
    background: ${theme.colors.white};
    border-radius: 50%;
  }

  ::after {
    position: absolute;
    content: '';
    display: block;
    top: 50%;
    left: 50%;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: ${theme.colors.black};
    transform: translate(-50%, -50%);

    ${media.md`
      width: 7px;
      height: 7px;
    `}

    ${media.lg`
      width: 9px;
      height: 9px;
    `};
  }
`;

const Scale = styled.div`
  z-index: -1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  ::before {
    position: absolute;
    content: '';
    display: block;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background: ${theme.colors.black};
    transform: translateY(-50%);
  }

  ::after {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 1px;
    background: ${theme.colors.black};
    transform: translateX(-50%);
  }
`;

const Hand = styled.div`
  position: absolute;
  top: ${props => 50 - props.$length * 100}%;
  left: 50%;
  height: ${props => props.$length * 100}%;
  width: 1px;
  background: ${props => theme.colors[props.$color]};
  transform-origin: 50% 100%;
  animation: ${props => handRotation(props.$startPos)}
    ${props => props.$duration}s
    ${props => (props.$steps ? `steps(${props.$duration})` : 'linear')} infinite;

  ::before {
    position: absolute;
    content: '';
    display: block;
    bottom: -${props => (0.075 / props.$length) * 100}%;
    left: 0;
    width: 1px;
    height: ${props => (0.075 / props.$length) * 100}%;
    background: ${props => theme.colors[props.$color]};
    transform: translateX(-50%);
  }
`;

const TIMEZONES = {
  NYC: 'America/New_York',
  CPH: 'Europe/Copenhagen',
  PAR: 'Europe/Paris',
};

export default ({ $color, timezone = 'NYC', ...props }) => {
  const time = DateTime.local().setZone(TIMEZONES[timezone]);

  const hours = ((time.hour + 11) % 12) + 1;
  const minutes = time.minute;
  const seconds = time.second;

  const secondsDelta = (seconds + 1) / 60;
  const minutesDelta = (minutes + 1) / 60 + secondsDelta / 60;

  return (
    <Box $display="flex" {...props}>
      <Face>
        <Scale />

        {/* Hours */}
        <Hand
          $startPos={hours * 30 + minutesDelta * 30}
          $length={0.25}
          $duration={60 * 60 * 24}
          $color="black"
        />

        {/* Minutes */}
        <Hand
          $startPos={minutes * 6 + secondsDelta * 6}
          $length={0.43}
          $duration={60 * 60}
          $color="black"
        />

        {/* Seconds */}
        <Hand
          $startPos={seconds * 6}
          $length={0.43}
          $duration={60}
          $steps
          $color="gray"
        />
      </Face>
    </Box>
  );
};
