import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';

import Symbols from './Symbols';
import Letter from './Letter';
import Box from '@/atoms/Box';

import theme from '@/styles/theme';

const Logo = styled(Box)`
  fill: ${props => theme.colors[props.$color]};
`;

export default ({ $color, delay = 1500, ...props }) => {
  const [animate, setAnimate] = useState(false);

  const [moduleRef, inView] = useInView({
    rootMargin: '-30% 0px -30% 0px',
  });

  // we start the animation once it's in view
  useEffect(() => {
    if (inView) {
      setAnimate(true);
    }
  }, [inView]);

  return (
    <>
      <Symbols />

      <Logo ref={moduleRef} $display="flex" $color={$color} {...props}>
        <Letter letter="n" numRandom={8} animate={animate} delay={delay} />

        <Letter
          letter="r"
          numRandom={6}
          animate={animate}
          delay={delay + 400}
        />

        <Letter
          letter="2"
          numRandom={6}
          animate={animate}
          delay={delay + 1200}
        />

        <Letter
          letter="1"
          numRandom={5}
          animate={animate}
          delay={delay + 1400}
        />

        <Letter
          letter="5"
          numRandom={5}
          animate={animate}
          delay={delay + 1500}
        />

        <Letter
          letter="4"
          numRandom={4}
          animate={animate}
          delay={delay + 1600}
        />
      </Logo>
    </>
  );
};
