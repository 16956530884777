import React, { useEffect, useRef } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { useDrag } from 'react-use-gesture';
import { useInView } from 'react-intersection-observer';

import useCarousel from '@/hooks/useCarousel';

import Slide from './Slide';
import Grid from '@/atoms/Grid';
import Box from '@/atoms/Box';
import Caption from '@/atoms/Caption';
import ArrowButton from '@/atoms/ArrowButton';

import { scale, media } from '@/styles/utils';
import { SPACING } from '@/utils/enums';

const NavButton = styled(ArrowButton)`
  z-index: 10;
  position: absolute;
  left: ${props => (props.variant === 'left' ? 0 : 'auto')};
  right: ${props => (props.variant === 'right' ? 0 : 'auto')};
  top: 50%;
  transform: translateY(-50%);

  ${media.md`
    left: ${props => (props.variant === 'left' ? scale(0.5) : 'auto')};
    right: ${props => (props.variant === 'right' ? scale(0.5) : 'auto')};
  `}
`;

const Carousel = styled(Box)`
  position: relative;
`;

export default ({
  images,
  layout,
  spacingBottom,
  caption,
  textColor,
  ...props
}) => {
  const [activeSlide, handlePrev, handleNext] = useCarousel(images.length);

  const intervalRef = useRef();

  const onNext = () => {
    clearInterval(intervalRef.current);
    handleNext();
    intervalRef.current = setInterval(() => handleNext(), 3000);
  };

  const onPrev = () => {
    clearInterval(intervalRef.current);
    handlePrev();
    intervalRef.current = setInterval(() => handleNext(), 3000);
  };

  const bindSwipe = useDrag(
    ({ swipe: [swipeX] }) => {
      if (swipeX === -1) {
        // swipe left
        onNext();
      } else if (swipeX === 1) {
        // swipe right
        onPrev();
      }
    },
    {
      axis: 'x',
      lockDirection: true,
    }
  );

  const [moduleRef, inView] = useInView({
    rootMargin: '-30% 0px -30% 0px',
  });

  // we start/pause the gallery on whether it is visible
  useEffect(() => {
    if (inView) {
      intervalRef.current = setInterval(() => handleNext(), 3000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [inView, intervalRef, handleNext]);

  if (!images || images.length === 0) return null;

  const Container = layout === 'bleed' ? undefined : Grid.Container;
  const marginBottom = SPACING[spacingBottom || 'normal'];

  return (
    <Carousel
      ref={moduleRef}
      as={Container}
      mb={marginBottom}
      {...bindSwipe()}
      {...props}
    >
      <Box position="relative">
        {images.map(({ key, ...image }, i) => (
          <Slide key={key} isActive={i === activeSlide} {...image} />
        ))}
      </Box>

      <NavButton variant="left" onClick={onPrev} />
      <NavButton variant="right" onClick={onNext} />

      {caption && <Caption layout={layout}>{caption}</Caption>}
    </Carousel>
  );
};

export const query = graphql`
  fragment moduleImageCarouselData on SanityModuleImageCarousel {
    layout
    spacingBottom
    caption
    images {
      key: _key
      ...imageWithAltData
    }
  }
`;
