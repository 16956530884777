import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Grid from '@/atoms/Grid';
import RichText from '@/atoms/RichText';

import theme from '@/styles/theme';

const Container = styled(Grid.Container)`
  :first-of-type {
    margin-top: 0;
  }
`;

export default ({ textRaw, textColor, ...props }) => {
  return (
    <Container mt={theme.spacing.xlarge} mb={theme.spacing.large} {...props}>
      <Grid>
        <Grid.Item w={[1, 10 / 12, 7 / 12]}>
          <RichText
            t="h2"
            $color={theme.colorSchemes[textColor].title}
            blocksRaw={textRaw.text}
          />
        </Grid.Item>
      </Grid>
    </Container>
  );
};

export const query = graphql`
  fragment moduleLargeTextData on SanityModuleLargeText {
    textRaw: _rawText
  }
`;
