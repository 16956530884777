import React from 'react';
import { graphql } from 'gatsby';

import Grid from '@/atoms/Grid';
import AnimatedHeadline from '@/components/AnimatedHeadline';

import theme from '@/styles/theme';

export default ({ animatedHeadline, textColor, ...props }) => {
  return (
    <Grid.Container
      mt={theme.spacing.large}
      mb={theme.spacing.medium}
      {...props}
    >
      <Grid>
        <Grid.Item w={[1, 1, 1, 10 / 12]} o={[0, 0, 0, 1 / 12]}>
          <AnimatedHeadline
            $color={textColor}
            t="displayHeadline"
            as="h2"
            {...animatedHeadline}
          />
        </Grid.Item>
      </Grid>
    </Grid.Container>
  );
};

export const query = graphql`
  fragment moduleAnimatedHeadlineData on SanityModuleAnimatedHeadline {
    animatedHeadline {
      ...animatedHeadlineData
    }
  }
`;
