import React from 'react';
import styled from 'styled-components';

import Grid from '@/atoms/Grid';
import Text from '@/atoms/Text';

import theme from '@/styles/theme';
import { Link } from 'gatsby';

import { media, scale } from '@/styles/utils';

const Content = styled(Text)`
  transition: color 0.1s ease-out;
`;

const Row = styled(Link)`
  display: block;
  box-sizing: content-box;
  border: 1px solid ${props => theme.colorSchemes[props.$colorScheme].title};
  border-width: 0 0 1px;
  height: ${scale(3)};
  text-decoration: none;
  overflow: hidden;

  ${media.lg`
    height: ${scale(5)};
  `};

  :first-child {
    border-width: 1px 0;
  }

  :hover ${Content}, :focus ${Content} {
    color: ${theme.colors.black};
  }
`;

export default ({ colorScheme, link, columns, ...props }) => {
  return (
    <Row to={link} $colorScheme={colorScheme} {...props}>
      <Grid $height="100%" $alignItems="center">
        {columns.map(
          ({ key, w, content, colorType = 'title', align = 'left' }) => (
            <Content
              as={Grid.Item}
              key={key}
              t={1}
              w={w}
              $textAlign={align}
              $color={theme.colorSchemes[colorScheme][colorType]}
            >
              {content}
            </Content>
          )
        )}
      </Grid>
    </Row>
  );
};
