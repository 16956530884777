import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import Grid from '@/atoms/Grid';
import Text from '@/atoms/Text';

import theme from '@/styles/theme';
import { media } from '@/styles/utils';

const Title = styled(Text)`
  ${media.md`
    border-top: 1px solid ${props => theme.colors[props.$color]};
  `}
`;

const TableText = styled(Text)`
  border: 1px solid ${props => theme.colors[props.$color]};
  border-width: 0 0 1px;
`;

const TableItem = styled(Grid.Item)`
  :first-child,
  :nth-child(2) {
    ${TableText} {
      border-width: 1px 0;
    }
  }
`;

export default ({ title, text, textColor, ...props }) => {
  return (
    <Grid.Container mb={theme.spacing.large} {...props}>
      <Grid>
        <Grid.Item w={[1, 1, 4 / 12]} o={[0, 0, 1 / 12]}>
          <Title
            t={1}
            as="h3"
            $color={theme.colorSchemes[textColor].title}
            pt={[0, 0, 1.25, 1.5]}
            pb={[1.25]}
          >
            {title}
          </Title>
        </Grid.Item>

        <Grid.Item w={[1, 1, 6 / 12]}>
          <Grid>
            {text.map(string => (
              <TableItem key={string} w={1 / 2}>
                <TableText
                  t={1}
                  py={[1.25, 1.25, 1.25, 1.5]}
                  $color={theme.colorSchemes[textColor].title}
                >
                  {string}
                </TableText>
              </TableItem>
            ))}
          </Grid>
        </Grid.Item>
      </Grid>
    </Grid.Container>
  );
};

export const query = graphql`
  fragment moduleTextTableData on SanityModuleTextTable {
    title
    text
  }
`;
