import React from 'react';
import styled from 'styled-components';
import { animated, useSpring, to } from 'react-spring';

import Box from '@/atoms/Box';

import { media } from '@/styles/utils';

const WIDTH = 282.16;
const HEIGHT = 540.92;
const LETTERS = 'nr0123456789';

const Container = styled(Box)`
  :not(:last-child) {
    margin-right: 2%;
  }
`;

const Svg = styled.svg`
  width: 100%;
  height: auto;

  /* responsive svgs on IE */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    padding-bottom: ${(HEIGHT / WIDTH) * 100}%;
    height: 1px;
    overflow: visible;
  }
`;

const Background = styled(Svg)`
  opacity: 0.2;
  fill: none;
  stroke: currentColor;
  stroke-miterlimit: 10;
  stroke-width: 4;

  ${media.sm`
    stroke-width: 3;
  `}

  ${media.md`
    stroke-width: 2;
  `}

  ${media.lg`
    stroke-width: 1.5;
  `}
`;

const Letter = styled(Svg)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 1;
`;

const AnimatedLetter = animated(Letter);

const getRandomLetter = (lettersToOmmit = null) => {
  // make sure we don't show the same letter twice directly after another
  const availableLetters =
    lettersToOmmit && lettersToOmmit.length > 0
      ? LETTERS.replace(new RegExp(`[${lettersToOmmit.join('')}]`, 'g'), '')
      : LETTERS;
  const rand = Math.round(Math.random() * (availableLetters.length - 1));

  return availableLetters[rand];
};

const getAnimationSequence = (numRandom, finalLetter) => {
  let lastLetter = null;

  // random sequence without doubbling up to the second to last letter
  const sequence = Array(numRandom - 1 || 0)
    .fill(0)
    .map(() => {
      const letter = getRandomLetter(lastLetter ? [lastLetter] : null);
      lastLetter = letter;

      return letter;
    });

  // make sure the second to last letter isn't the same as the final one
  const secondToLastLetter = getRandomLetter([lastLetter, finalLetter]);

  return [...sequence, secondToLastLetter, finalLetter];
};

export default ({
  letter: finalLetter,
  numRandom = 5,
  delay = 0,
  animate = false,
  ...props
}) => {
  const animationSequence = getAnimationSequence(numRandom, finalLetter);

  const { n } = useSpring({
    from: { n: -1 },
    to: { n: animate ? animationSequence.length - 1 : -1 },
    delay,
    config: {
      clamp: true,
      precision: 1,
    },
  });

  return (
    <Container $position="relative" $flex="1 1 100px" {...props}>
      <Background
        viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
        preserveAspectRatio="xMidYMin slice"
        xmlns="http://www.w3.org/2000/svg"
      >
        <use xlinkHref="#logo-bg" />
      </Background>

      {LETTERS.split('').map(l => (
        <AnimatedLetter
          key={l}
          viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
          preserveAspectRatio="xMidYMin slice"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            opacity: to([n], i =>
              i !== -1 && animationSequence[i.toFixed(0)] === l ? 1 : 0
            ),
          }}
        >
          <use xlinkHref={`#logo-${l}`} />
        </AnimatedLetter>
      ))}
    </Container>
  );
};
