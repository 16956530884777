import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

import useMeasure from '@/hooks/useMeasure';

import ToggleButton from './ToggleButton';
import Box from '@/atoms/Box';
import Text from '@/atoms/Text';
import RichText from '@/atoms/RichText';

import theme from '@/styles/theme';

const Container = styled(Box)`
  border-bottom: 1px solid ${props => theme.colors[props.$color]};
`;

const ExpandableContent = styled(animated.div)`
  overflow: hidden;
  position: relative;
`;

export default ({
  isActive,
  handleToggle,
  name,
  role,
  bioRaw,
  textColor,
  ...props
}) => {
  const [measureRef, { height }] = useMeasure();
  const springProps = useSpring({
    height: isActive ? height : 0,
    opacity: isActive ? 1 : 0,
  });

  return (
    <Container as="article" $color={textColor} {...props}>
      <Box
        as="header"
        $display="flex"
        $alignItems="center"
        py={[1.25, 1.25, 1.25, 1.5]}
      >
        <Box $flex="1 1 auto">
          <Text t={1} $color={theme.colorSchemes[textColor].title}>
            {name}
          </Text>

          {role && (
            <Text t={1} $color={theme.colorSchemes[textColor].text}>
              {role.title}
            </Text>
          )}
        </Box>

        <ToggleButton
          onClick={handleToggle}
          icon={isActive ? 'close' : 'open'}
          $color={theme.colorSchemes[textColor].title}
          mr={[0, 0, 0, 0.5]}
        />
      </Box>

      <ExpandableContent style={springProps}>
        <div ref={measureRef}>
          {bioRaw && (
            <RichText
              t={1}
              pt={[0.25, 0.25, 0.25, 0.5]}
              pb={[1.5, 1.5, 1.5, 4]}
              pr={[4, 4, 4, 6]}
              $color={theme.colorSchemes[textColor].text}
              blocksRaw={bioRaw.text}
            />
          )}
        </div>
      </ExpandableContent>
    </Container>
  );
};
