import React from 'react';
import { graphql } from 'gatsby';

import Box from '@/atoms/Box';
import Grid from '@/atoms/Grid';
import Caption from '@/atoms/Caption';
import Image from '@/components/Image';

import { SPACING } from '@/utils/enums';

export default ({
  image,
  layout,
  spacingBottom,
  caption,
  textColor,
  ...props
}) => {
  const Container = layout === 'bleed' ? undefined : Grid.Container;
  const marginBottom = SPACING[spacingBottom || 'normal'];

  return (
    <Box as={Container} mb={marginBottom} {...props}>
      <Image {...image} />

      {caption && <Caption layout={layout}>{caption}</Caption>}
    </Box>
  );
};

export const query = graphql`
  fragment moduleImageData on SanityModuleImage {
    layout
    spacingBottom
    caption
    image {
      ...imageWithAltData
    }
  }
`;
