import React from 'react';
import { graphql } from 'gatsby';

import Text from '@/modules/Text';
import RichText from '@/modules/RichText';
import LargeText from '@/modules/LargeText';
import TextColumns from '@/modules/TextColumns';
import TextTable from '@/modules/TextTable';
import AnimatedHeadline from '@/modules/AnimatedHeadline';
import Image from '@/modules/Image';
import ImageCarousel from '@/modules/ImageCarousel';
import AssetGrid from '@/modules/AssetGrid';
import Jobs from '@/modules/Jobs';
import Video from '@/modules/Video';
import Persons from '@/modules/Persons';
import AboutIntro from '@/modules/AboutIntro';
import ContactInfo from '@/modules/ContactInfo';
import LogoAnimation from '@/modules/LogoAnimation';

export default ({ contentModules, textColor = 'black' }) => {
  const { modules } = contentModules || { modules: [] };

  return (
    <>
      {modules.map(({ id, type, ...props }) => {
        let module = null;
        const moduleProps = {
          key: id,
          textColor,
          ...props,
        };

        if (type === 'moduleText') {
          module = <Text {...moduleProps} />;
        } else if (type === 'moduleRichText') {
          module = <RichText {...moduleProps} />;
        } else if (type === 'moduleLargeText') {
          module = <LargeText {...moduleProps} />;
        } else if (type === 'moduleTextColumns') {
          module = <TextColumns {...moduleProps} />;
        } else if (type === 'moduleTextTable') {
          module = <TextTable {...moduleProps} />;
        } else if (type === 'moduleAnimatedHeadline') {
          module = <AnimatedHeadline {...moduleProps} />;
        } else if (type === 'moduleImage') {
          module = <Image {...moduleProps} />;
        } else if (type === 'moduleImageCarousel') {
          module = <ImageCarousel {...moduleProps} />;
        } else if (type === 'moduleAssetGrid') {
          module = <AssetGrid {...moduleProps} />;
        } else if (type === 'moduleJobs') {
          module = <Jobs {...moduleProps} />;
        } else if (type === 'moduleVideo') {
          module = <Video {...moduleProps} />;
        } else if (type === 'modulePersons') {
          module = <Persons {...moduleProps} />;
        } else if (type === 'moduleAboutIntro') {
          module = <AboutIntro {...moduleProps} />;
        } else if (type === 'moduleContactInfo') {
          module = <ContactInfo {...moduleProps} />;
        } else if (type === 'moduleLogoAnimation') {
          module = <LogoAnimation {...moduleProps} />;
        }

        return module;
      })}
    </>
  );
};

export const query = graphql`
  fragment contentModulesData on SanityContentModules {
    modules {
      ... on SanityModuleText {
        id: _key
        type: _type
        ...moduleTextData
      }
      ... on SanityModuleRichText {
        id: _key
        type: _type
        ...moduleRichTextData
      }
      ... on SanityModuleLargeText {
        id: _key
        type: _type
        ...moduleLargeTextData
      }
      ... on SanityModuleTextColumns {
        id: _key
        type: _type
        ...moduleTextColumnsData
      }
      ... on SanityModuleTextTable {
        id: _key
        type: _type
        ...moduleTextTableData
      }
      ... on SanityModuleAnimatedHeadline {
        id: _key
        type: _type
        ...moduleAnimatedHeadlineData
      }
      ... on SanityModuleAssetGrid {
        id: _key
        type: _type
        ...moduleAssetGridData
      }
      ... on SanityModuleImage {
        id: _key
        type: _type
        ...moduleImageData
      }
      ... on SanityModuleImageCarousel {
        id: _key
        type: _type
        ...moduleImageCarouselData
      }
      ... on SanityModuleJobs {
        id: _key
        type: _type
        ...moduleJobsData
      }
      ... on SanityModuleVideo {
        id: _key
        type: _type
        ...moduleVideoData
      }
      ... on SanityModulePersons {
        id: _key
        type: _type
        ...modulePersonsData
      }
      ... on SanityModuleAboutIntro {
        id: _key
        type: _type
        ...moduleAboutIntroData
      }
      ... on SanityModuleLogoAnimation {
        id: _key
        type: _type
      }
      ... on SanityModuleContactInfo {
        id: _key
        type: _type
        ...moduleContactInfoData
      }
    }
  }
`;
