import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import VideoPlayer from '@/components/VideoPlayer';

export default ({ columnWidth, ...video }) => {
  const [isPlaying, setPlaying] = useState(false);
  const [inViewRef, inView] = useInView({
    rootMargin: '-30% 0px -30% 0px',
  });

  // we start/pause the videos depending on whether the video is visible
  useEffect(() => {
    setPlaying(inView);
  }, [inView]);

  return (
    <div ref={inViewRef}>
      <VideoPlayer
        vimeoVideo={video}
        columnWidth={columnWidth}
        autoplay={false}
        play={isPlaying}
        preload
        loop
        muted
        playsInline
      />
    </div>
  );
};
