import React from 'react';
import { graphql } from 'gatsby';

import TitleWithOverline from '@/components/TitleWithOverline';
import Table from '@/components/Table';
import Grid from '@/atoms/Grid';
import RichText from '@/atoms/RichText';

import theme from '@/styles/theme';
import { getUri } from '@/utils/routing';
import { LOCATIONS } from '@/utils/enums';

export default ({ title, description, jobs, textColor, ...props }) => {
  const tableData = jobs.map(job => ({
    key: job.key,
    link: getUri(job.slug.current, 'job'),
    columns: [
      {
        key: 'location',
        content: LOCATIONS[job.location].short,
        w: [2 / 12, 2 / 12, 2 / 12, 1 / 8],
        colorType: 'text',
      },
      {
        key: 'title',
        content: job.title,
        w: [9 / 12, 9 / 12, 9 / 12, 6 / 8],
        colorType: 'title',
      },
      {
        key: 'arrow',
        content: '→',
        w: [1 / 12, 1 / 12, 1 / 12, 1 / 8],
        colorType: 'title',
        align: 'right',
      },
    ],
  }));

  return (
    <Grid.Container mb={theme.spacing.large} {...props}>
      <Grid>
        <Grid.Item w={[1, 1, 4 / 12]} mb={[3, 3, 0]}>
          <TitleWithOverline
            as="h3"
            $color={theme.colorSchemes[textColor].title}
          >
            {title}
          </TitleWithOverline>

          <RichText
            blocksRaw={description.textRaw}
            $color={theme.colorSchemes[textColor].text}
            $maxWidth={18}
            pb={[0.5, 0.5, 2.5]}
          />
        </Grid.Item>

        <Grid.Item w={[1, 1, 8 / 12]}>
          <Table rows={tableData} colorScheme="black" />
        </Grid.Item>
      </Grid>
    </Grid.Container>
  );
};

export const query = graphql`
  fragment moduleJobsData on SanityModuleJobs {
    title
    description: text {
      textRaw: _rawText
    }
    jobs {
      key: _id
      title
      location
      slug {
        current
      }
    }
  }
`;
