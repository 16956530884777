import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { useInView } from 'react-intersection-observer';

import VideoPlayer from '@/components/VideoPlayer';
import Box from '@/atoms/Box';
import Grid from '@/atoms/Grid';
import Caption from '@/atoms/Caption';

import { SPACING } from '@/utils/enums';

export default ({
  layout,
  spacingBottom,
  caption,
  video,
  portraitVideo,
  textColor,
  ...props
}) => {
  const [isPlaying, setPlaying] = useState(false);
  const [moduleRef, inView] = useInView({
    rootMargin: '-30% 0px -30% 0px',
  });

  const Container = layout === 'bleed' ? undefined : Grid.Container;
  const marginBottom = SPACING[spacingBottom || 'normal'];

  const artDirectedVideo = portraitVideo
    ? [
        {
          video,
          media: '(orientation: landscape)',
        },
        {
          video: portraitVideo,
        },
      ]
    : video;

  // we start/pause the video depending on whether the video is visible
  useEffect(() => {
    setPlaying(inView);
  }, [inView]);

  return (
    <Box ref={moduleRef} as={Container} mb={marginBottom} {...props}>
      <VideoPlayer
        vimeoVideo={artDirectedVideo}
        autoplay={false}
        play={isPlaying}
        preload
        loop
        muted
        playsInline
      />

      {caption && <Caption layout={layout}>{caption}</Caption>}
    </Box>
  );
};

export const query = graphql`
  fragment moduleVideoData on SanityModuleVideo {
    layout
    spacingBottom
    caption
    video {
      ...vimeoData
    }
    portraitVideo {
      ...vimeoData
    }
  }
`;
