import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import useViewportHeight from '@/hooks/useViewportHeight';

import LogoAnimation from '@/components/LogoAnimation';
import Grid from '@/atoms/Grid';
import Text from '@/atoms/Text';
import GridOverline from '@/atoms/GridOverline';

import theme from '@/styles/theme';
import { media, scale } from '@/styles/utils';

const Title = styled(Text)`
  border-bottom: 1px solid ${props => theme.colors[props.$color]};

  ${media.md`
    border-bottom: 0;
  `}
`;

export default ({ textLeft, textRight, textColor, ...props }) => {
  const [vh] = useViewportHeight();

  return (
    <Grid.Container
      $display="flex"
      $flexDirection="column"
      $justifyContent="space-between"
      $minHeight={theme.spacing.contentTop.map(
        top => `calc(${vh ? `${vh}px` : '100vh'} - ${scale(top)})`
      )}
      {...props}
    >
      <Grid pb={theme.spacing.large}>
        <GridOverline
          w={[1, 1, 10 / 12]}
          o={[0, 0, 1 / 12]}
          mb={[0, 0, 1.25, 1.5]}
          $color={theme.colorSchemes[textColor].title}
          $display={['none', 'none', 'block']}
        />

        <Grid.Item w={[1, 1, 4 / 12]} o={[0, 0, 1 / 12]}>
          <Title
            t={1}
            as="h3"
            $color={theme.colorSchemes[textColor].title}
            pb={[1.25]}
            mb={[1.25]}
          >
            {textLeft}
          </Title>
        </Grid.Item>

        <Grid.Item w={[1, 1, 6 / 12]}>
          <Text t={1} $color={theme.colorSchemes[textColor].text}>
            {textRight}
          </Text>
        </Grid.Item>
      </Grid>

      <LogoAnimation $color={textColor} pb={2} delay={0} />
    </Grid.Container>
  );
};

export const query = graphql`
  fragment moduleAboutIntroData on SanityModuleAboutIntro {
    textLeft
    textRight
  }
`;
