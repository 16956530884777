import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import Grid from '@/atoms/Grid';
import Text from '@/atoms/Text';
import RichText from '@/atoms/RichText';
import GridOverline from '@/atoms/GridOverline';

import theme from '@/styles/theme';
import { media } from '@/styles/utils';

const Title = styled(Text)`
  border-bottom: 1px solid ${props => theme.colors[props.$color]};

  ${media.md`
    border-bottom: 0;
  `}
`;

export default ({ title, columns, textColor, ...props }) => {
  return (
    <Grid.Container mb={theme.spacing.large} {...props}>
      <Grid>
        <GridOverline
          w={[1, 1, 10 / 12]}
          o={[0, 0, 1 / 12]}
          mb={[0, 0, 1.25, 1.5]}
          $color={theme.colorSchemes[textColor].title}
          $display={['none', 'none', 'block']}
        />

        <Grid.Item w={[1, 1, 4 / 12]} o={[0, 0, 1 / 12]}>
          <Title
            t={1}
            as="h3"
            $color={theme.colorSchemes[textColor].title}
            pb={[1.25]}
            mb={[1.25]}
          >
            {title}
          </Title>
        </Grid.Item>

        <Grid.Item w={[1, 1, 6 / 12]}>
          <Grid mb={[-2.5, -2.5, -2.5, -4]}>
            {columns.map(column => (
              <Grid.Item key={column.key} w={1 / 2} mb={[2.5, 2.5, 2.5, 4]}>
                <Text
                  t={1}
                  as="h4"
                  $color={theme.colorSchemes[textColor].title}
                >
                  {column.title}
                </Text>

                <RichText
                  t={1}
                  $color={theme.colorSchemes[textColor].text}
                  blocksRaw={column.textRaw.text}
                />
              </Grid.Item>
            ))}
          </Grid>
        </Grid.Item>
      </Grid>
    </Grid.Container>
  );
};

export const query = graphql`
  fragment moduleTextColumnsData on SanityModuleTextColumns {
    title
    columns {
      key: _key
      title
      textRaw: _rawText
    }
  }
`;
