import React from 'react';
import { graphql } from 'gatsby';

import Grid from '@/atoms/Grid';
import Text from '@/atoms/Text';
import RichText from '@/atoms/RichText';

import theme from '@/styles/theme';
import { SPACING } from '@/utils/enums';

export default ({ title, textRaw, spacingBottom, textColor, ...props }) => {
  const marginBottom = SPACING[spacingBottom || 'normal'];

  return (
    <Grid.Container mb={marginBottom} {...props}>
      <Grid>
        <Grid.Item w={[10 / 12, 10 / 12, 6 / 12]} o={[0, 0, 5 / 12]}>
          <Text t={1} as="h3" $color={theme.colorSchemes[textColor].title}>
            {title}
          </Text>

          <RichText
            t={1}
            $color={theme.colorSchemes[textColor].text}
            blocksRaw={textRaw.text}
          />
        </Grid.Item>
      </Grid>
    </Grid.Container>
  );
};

export const query = graphql`
  fragment moduleTextData on SanityModuleText {
    title
    textRaw: _rawText
    spacingBottom
  }
`;
