import React from 'react';
import styled from 'styled-components';

const Symbols = styled.svg`
  display: none;
`;

export default () => {
  return (
    <Symbols>
      <defs>
        <symbol id="logo-bg">
          <circle cx="141.5" cy="400.2" r="129" />
          <circle cx="141.5" cy="142.2" r="129" />
          <path d="M12.5 13.2h258v516h-258zM141.5 13.2v515.5M12.5 529.2l258-516M12.5 13.2l258 516M12.5 271.2h258" />
        </symbol>

        <symbol id="logo-n">
          <path d="M8.2 532.48V10.62h4.47L266.17 517V10.62h5.91v521.86h-4.78L14.11 26.09v506.39z" />
        </symbol>

        <symbol id="logo-r">
          <path d="M267.8 533L138.3 273.8H14.1v258H8.2V9.9h132c72.8 0 132 59.6 132 132 0 71.3-57.1 129.4-127.3 131.9l128.3 256.6-5.4 2.6zM14.1 267.9h126c69.4 0 126.1-56.1 126.1-126.1 0-69.9-56.6-126-126.1-126h-126v252.1z" />
        </symbol>

        <symbol id="logo-0">
          <path d="M140.14 10.62c72.34 0 131.94 59.11 131.94 131.94v258c0 72.83-59.6 132-131.94 132-72.84 0-131.94-59.6-131.94-132v-258c0-72.34 59.1-131.94 131.94-131.94zM14.1 400.53a126.7 126.7 0 0018 64.9l7.9-.23L239.83 65.53a126 126 0 00-225.73 77zm126 126a126 126 0 00126-126v-258a126.72 126.72 0 00-18-64.92l-7.86.23-199.82 399.7a127.19 127.19 0 0099.72 49.03z" />
        </symbol>

        <symbol id="logo-1">
          <path d="M272.08 526.57v5.91H8.2v-5.91h129v-510H8.2v-5.95h134.89v516z" />
        </symbol>

        <symbol id="logo-2">
          <path d="M14.11 400.54v126h258v5.91H8.21l.07-136.61c0-23.13 7.4-46.82 22.46-69.09a131.76 131.76 0 01109.4-58.18 126.84 126.84 0 0070.46-21.51 125.76 125.76 0 0055.56-104.51A126.05 126.05 0 0069.68 38.06a125.77 125.77 0 00-55.43 98.61H8.33a129.76 129.76 0 0122.41-67.86 132 132 0 01183.17-35.63 132 132 0 0135.64 183.18 131.74 131.74 0 01-109.41 58.17 125.94 125.94 0 00-126 126z" />
        </symbol>

        <symbol id="logo-3">
          <path d="M213.9 291.13a131.93 131.93 0 01-73.77 241.35 132 132 0 01-121.56-80.59 129.53 129.53 0 01-10.25-45.45h5.92A126 126 0 00210.59 505 126 126 0 00146 274.64v-6.18a126 126 0 0098.6-196.35 125.84 125.84 0 00-104.47-55.56A125.92 125.92 0 0014.24 136.67H8.32a129.76 129.76 0 0122.41-67.86A132 132 0 01213.9 33.18a132 132 0 01-22.41 231c-5.51 2.33-10.9 4.05-23.56 7.25 17.54 4.13 32.29 10.5 46 19.75z" />
        </symbol>

        <symbol id="logo-4">
          <path d="M266.17 10.62h5.91v521.86h-5.91V274.67H8.2v-264h5.9v258.1h252.07z" />
        </symbol>

        <symbol id="logo-5">
          <path d="M8.32 406.44h5.92A126.06 126.06 0 00210.6 505a126 126 0 00-70.46-230.53H8.2V10.63h263.88v5.91H14.1V268.6h126a131.84 131.84 0 01131.94 132 132 132 0 01-80.6 121.57 132 132 0 01-172.88-70.26 130.08 130.08 0 01-10.25-45.46z" />
        </symbol>

        <symbol id="logo-6">
          <path d="M140.13 268.6c72.35 0 131.94 59.1 131.94 131.93 0 72.35-59.11 132-131.94 132-72.34 0-131.94-59.12-131.94-132v-258c0-72.31 59.11-131.91 131.94-131.91 70.6 0 128.7 56.18 131.8 126H266a126 126 0 00-251.9 5.94v218.86a132.48 132.48 0 01126.03-92.82zm0 258a126 126 0 10-126-126 126 126 0 00126 125.97z" />
        </symbol>

        <symbol id="logo-7">
          <path d="M272.08 10.59v3.6L13.9 530.6 8.61 528 264.34 16.5H8.2v-5.91z" />
        </symbol>

        <symbol id="logo-8">
          <path d="M167.8 271.58c20.37 3.75 33.23 10.85 46.1 19.55a131.93 131.93 0 01-73.77 241.35A132 132 0 0188.78 279a107.93 107.93 0 0126.45-7.67C93.82 267.16 79.6 260.91 66.37 252A132 132 0 0188.79 21a132 132 0 01172.93 70.21 132 132 0 01-70.21 172.93c-5.63 2.38-11.1 4.12-23.69 7.45zM91.09 258.7a126.25 126.25 0 00119.51-11.62 125.87 125.87 0 0055.58-104.51A126.06 126.06 0 0069.69 38.06a125.84 125.84 0 00-55.56 104.52 126.08 126.08 0 0077 116.12zm165.2 190.89A126.06 126.06 0 0069.71 296a126 126 0 0070.46 230.53 126.09 126.09 0 00116.13-77z" />
        </symbol>

        <symbol id="logo-9">
          <path d="M140.13 10.62c72.83 0 131.94 59.6 131.94 131.94v258c0 72.35-59.11 132-131.94 132-70.12 0-128.69-55.72-131.81-126h5.92a126 126 0 00251.92-5.91V181.67a132.48 132.48 0 01-126 92.83c-72.37 0-131.97-59.11-131.97-131.94 0-72.34 59.11-131.94 131.94-131.94zm0 258a126 126 0 10-126-126 126 126 0 00126 125.98z" />
        </symbol>
      </defs>
    </Symbols>
  );
};
