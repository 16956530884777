import React, { useState } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import Person from './Person';
import Grid from '@/atoms/Grid';
import Text from '@/atoms/Text';
import GridOverline from '@/atoms/GridOverline';

import theme from '@/styles/theme';
import { media } from '@/styles/utils';

const Title = styled(Text)`
  border-bottom: 1px solid ${props => theme.colors[props.$color]};

  ${media.md`
    border-bottom: 0;
  `}
`;

export default ({ title, persons, textColor, ...props }) => {
  const [activeItem, setActiveItem] = useState(null);

  const toggleItem = i => {
    if (i === activeItem) {
      setActiveItem(null);
    } else {
      setActiveItem(i);
    }
  };

  return (
    <Grid.Container mb={theme.spacing.large} {...props}>
      <Grid>
        <GridOverline
          w={[1, 1, 10 / 12]}
          o={[0, 0, 1 / 12]}
          $color={theme.colorSchemes[textColor].title}
          $display={['none', 'none', 'block']}
        />

        <Grid.Item w={[1, 1, 4 / 12]} o={[0, 0, 1 / 12]}>
          <Title
            t={1}
            as="h3"
            $color={theme.colorSchemes[textColor].title}
            pt={[0, 0, 1.25, 1.5]}
            pb={[1.25]}
          >
            {title}
          </Title>
        </Grid.Item>

        <Grid.Item w={[1, 1, 6 / 12]}>
          {persons.map(({ key, ...person }, i) => (
            <Person
              key={key}
              isActive={i === activeItem}
              textColor={textColor}
              handleToggle={() => toggleItem(i)}
              {...person}
            />
          ))}
        </Grid.Item>
      </Grid>
    </Grid.Container>
  );
};

export const query = graphql`
  fragment modulePersonsData on SanityModulePersons {
    title
    persons {
      key: _id
      name
      role {
        title
      }
      bioRaw: _rawBio
    }
  }
`;
