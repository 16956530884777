import React from 'react';
import styled from 'styled-components';

import Image from '@/components/Image';

const Slide = styled.div`
  z-index: ${props => (props.isActive ? 1 : 0)};
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  opacity: ${props => (props.isActive ? 1 : 0)};
  transition: opacity 0.5s ease-out;

  &:first-child {
    position: static;
  }

  * {
    -webkit-user-drag: none;
    user-select: none;
  }
`;

export default ({ isActive, ...image }) => (
  <Slide isActive={isActive}>
    <Image {...image} />
  </Slide>
);
