import React from 'react';
import { graphql } from 'gatsby';

import Grid from '@/atoms/Grid';
import RichText from '@/atoms/RichText';

import { SPACING } from '@/utils/enums';

export default ({ textRaw, spacingBottom, textColor, ...props }) => {
  const marginBottom = SPACING[spacingBottom || 'normal'];

  return (
    <Grid.Container mb={marginBottom} {...props}>
      <Grid>
        <Grid.Item w={[10 / 12, 10 / 12, 8 / 12, 7 / 12]}>
          <RichText $color={textColor} blocksRaw={textRaw.text} />
        </Grid.Item>
      </Grid>
    </Grid.Container>
  );
};

export const query = graphql`
  fragment moduleRichTextData on SanityModuleRichText {
    textRaw: _rawText
    spacingBottom
  }
`;
