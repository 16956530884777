import styled from 'styled-components';

import IconButton from '@/atoms/IconButton';

import theme from '@/styles/theme';
import { scale } from '@/styles/utils';

const VARIANTS = {
  marginLeft: {
    left: scale(0.25),
    right: scale(-0.25),
    up: 0,
    down: 0,
  },
  marginTop: {
    left: 0,
    right: 0,
    up: scale(0.25),
    down: scale(-0.25),
  },
  rotation: {
    left: '-135deg',
    right: '45deg',
    up: '-45deg',
    down: '135deg',
  },
};

export default styled(IconButton).attrs(props => ({
  $size: props.$size || 0.8,
}))`
  ::after {
    content: '';
    display: block;
    position: absolute;
    left: calc(50% - ${props => scale(props.$size / 2)});
    top: calc(50% - ${props => scale(props.$size / 2)});
    margin-top: ${props => VARIANTS.marginTop[props.variant]};
    margin-left: ${props => VARIANTS.marginLeft[props.variant]};
    width: ${props => scale(props.$size)};
    height: ${props => scale(props.$size)};
    border: 1px solid ${theme.colors.black};
    border-width: 1px 1px 0 0;
    transform: scale(1.25) rotate(${props => VARIANTS.rotation[props.variant]});
    transform-origin: center center;
  }
`;
