import React from 'react';

import Row from './Row';
import Box from '@/atoms/Box';

export default ({ rows, colorScheme = 'black', ...props }) => {
  return (
    <Box {...props}>
      {rows.map(({ key, ...row }) => (
        <Row key={key} colorScheme={colorScheme} {...row} />
      ))}
    </Box>
  );
};
