import React, { useState } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import useInterval from '@/hooks/useInterval';

import Text from '@/atoms/Text';

const easeInOutCubic = 'cubic-bezier(0.65, 0, 0.35, 1)';

const AnimatedTextHolder = styled.span`
  position: relative;
  display: block;
  overflow: hidden;
`;

const AnimatedText = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: block;
  opacity: ${props => (props.isActive ? 1 : 0)};
  transition: all 0.6s ${easeInOutCubic};
  transform: translateY(
    ${props => {
      if (props.isActive) {
        return '0';
      }
      if (props.isExiting) {
        return '-100%';
      }
      return '100%';
    }}
  );

  :first-child {
    position: static;
  }
`;

export default ({ staticText, animatedText, t = 'h1', ...props }) => {
  const [activeItem, setActiveItem] = useState(0);

  useInterval(() => {
    setActiveItem((activeItem + 1) % animatedText.length);
  }, 2500);

  return (
    <Text t={t} {...props}>
      {staticText}
      <AnimatedTextHolder>
        {animatedText.map((text, i) => (
          <AnimatedText
            key={text}
            isActive={i === activeItem}
            isExiting={
              (i === animatedText.length - 1 && activeItem === 0) ||
              i + 1 === activeItem
            }
          >
            {text}
          </AnimatedText>
        ))}
      </AnimatedTextHolder>
    </Text>
  );
};

export const query = graphql`
  fragment animatedHeadlineData on SanityAnimatedHeadline {
    staticText
    animatedText
  }
`;
